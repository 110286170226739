<template>
  <div>
    <div class="row align-items-end">
      <div class="col-3">
        <label for=""> Fournisseur <span class="text-danger">*</span> </label>
        <select
          v-model="search.supplier_reference"
          class="form-select"
          required
        >
          <option selected value="--">--</option>
          <option
            v-for="(supplier, index) in suppliers"
            :key="index++"
            :value="supplier.reference"
            :style="
              supplier.blocked || supplier.society.includes('Bloqué')
                ? { color: 'red' }
                : ''
            "
          >
            {{ supplier.society }}
          </option>
        </select>
      </div>

      <div class="col">
        <label for="">Du <span class="text-danger">*</span> </label>
        <input v-model="search.startDate" type="date" class="form-control" />
      </div>
      <div class="col">
        <label for=""> Au <span class="text-danger">*</span> </label>
        <input v-model="search.endDate" type="date" class="form-control" />
      </div>

      <div class="col-2">
        <label for=""> État de paiement </label>
        <select v-model="search.paymentStatus" class="form-select">
          <option value="--">--</option>
          <option value="Payé">Payé</option>
          <option value="Impayé">Impayé</option>
        </select>
      </div>
      <div class="col-auto">
        <label for=""> </label>
        <button @click="getReport(search)" class="btn btn-info">
          <i class="bi bi-search"></i> Rechercher
        </button>
      </div>
      <div class="col-auto">
        <label for=""> </label>
        <button @click="refresh()" class="btn btn-info">
          <i class="bi bi-search"></i> Actualiser
        </button>
      </div>
    </div>
    <br />

    <br />
    <div class="bg-white px-2">
      <div class="row border border-secondary fw-bolder py-2 px-0">
        <div class="col-4">Facture</div>
        <div class="col-8">
          <div class="row">
            <div class="col-6">Désignation</div>
            <div class="col-2">Qté</div>
            <div class="col-2">P.U</div>
            <div class="col-2">Total</div>
          </div>
        </div>
      </div>
      <div
        v-for="(report, index) in reports"
        :key="index++"
        class="row border align-items-stratch p-0"
      >
        <div class="col-4 border-end m-0">
          <div class="row">
            <div class="col">Date :</div>
            <div class="col">
              {{ report.date }}
            </div>
          </div>
          <div class="row">
            <div class="col">Total :</div>
            <div class="col">
              {{ report.totalPriceTTC }}
            </div>
          </div>
          <div class="row">
            <div class="col">Payé :</div>
            <div class="col text-success">
              {{ report.amountPaid }}
            </div>
          </div>
          <div class="row">
            <div class="col">Impayé :</div>
            <div class="col text-danger">
              {{ report.amountUnpaid }}
            </div>
          </div>

          <div class="row d-flex justify-content-center p-2">
            <button
              @click="
                $router.push({
                  name: 'purchases-details',
                  params: {
                    reference: report.reference,
                  },
                })
              "
              class="btn btn-primary mx-2"
            >
              Afficher
            </button>
          </div>
        </div>

        <div class="col-8 border border-secondary m-0">
          <div
            v-for="(purchase_item, _index) in report.purchase_items"
            :key="_index++"
            class="row py-1"
            :class="_index >= report.purchase_items.lenght ? 'border-0' : ''"
          >
            <div class="col-6">
              <span v-if="purchase_item.item">
                {{ purchase_item.item.fullName }}
              </span>
            </div>

            <div class="col-2">
              {{ purchase_item.quantity }}
            </div>
            <div class="col-2">
              {{ purchase_item.priceTTC }}
            </div>

            <div class="col-2">
              {{ purchase_item.totalPriceTTC }}
            </div>
          </div>
        </div>
      </div>

      <div class="row border border-secondary fw-bolder py-2 px-0">
        <div class="col-3"></div>
        <div class="col-6">
          <div class="row">
            <div class="col-6">Montant Total :</div>
            <div class="col-6 bg-warning my-1">
              {{ totalPriceTTC | toFixedWithSpace }}
            </div>
          </div>
          <div class="row">
            <div class="col-6">Montant payé :</div>
            <div class="col-6 bg-success text-white my-1">
              {{ totalPaid | toFixedWithSpace }}
            </div>
          </div>
          <div class="row">
            <div class="col-6">Montant impayé :</div>
            <div class="col-6 bg-danger text-white my-1">
              {{ totalUnpaid | toFixedWithSpace }}
            </div>
          </div>

          <div class="row">
            <div class="col-6">Quantité totale :</div>
            <div class="col-6 bg-secondary text-white my-1">
              {{ totalQuantity | toFixedWithSpace }}
            </div>
          </div>
        </div>
        <!-- <div class="col-3">
          {{ totalReportPayments }}
        </div> -->
      </div>
    </div>

    <div v-if="reports != ''" class="row justify-content-end">
      <div class="col-auto">
        <label for=""> </label>
        <button @click="print(search)" class="btn btn-info">
          <i class="bi bi-cloud-arrow-down"></i>
          Télécharger
        </button>
      </div>
    </div>

    <br />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  components: {},

  data() {
    return {
      search: {
        startDate: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],

        endDate: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
      },
      supplier_reference: "",
    };
  },

  beforeMount() {
    this.$store.dispatch("supplier/getAll");
    this.$store.dispatch("purchase/reportSupplier", this.search);
    // this.$store.dispatch("purchase/show", supplier_reference);
  },
  computed: {
    ...mapGetters("supplier", {
      suppliers: "getAll",
    }),
    ...mapGetters("purchase", {
      reports: "getReport",

      totalUnpaid: "getReportTotalUnpaid",
      totalPaid: "getReportTotalPaid",
      totalPriceTTC: "getReportTotalPriceTTC",
      totalQuantity: "getReportTotalQuantity",
    }),
  },
  methods: {
    async refresh() {
      await this.$store.dispatch("supplier/getAll");
      this.search = {};
    },
    async getReport(data) {
      await this.$store.dispatch("purchase/reportSupplier", data);
    },
    async print(data) {
      await this.$store.dispatch("purchase/printReportSupplier", data);
    },
  },
  filters: {
    toFixedWithSpace: (value) => {
      return parseFloat(value)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    },
  },
};
</script>
